body {
    font-family: 'Lato', sans-serif;
}
ul {
    padding: 0;
    list-style: none;
}

@media only screen and (min-height:551px) and (max-height:900px) and (orientation:portrait) {
    body{
        height: 100% !important;
        min-height: calc(100vh - 57px) !important;
    }
}